import { Container, Row, Col} from "react-bootstrap";
import LaptopImg from "../assets/about.png";
import Tilt from "react-parallax-tilt";
const About = () => {
  return (
    <>
       <Container>
            <Row>
              <Col md={8} className="home-about-description">
                <h1 style={{ fontSize: "2.6em" }}>
                 About <span className="yellow"> US </span> 
                </h1>
                <p className="home-about-body">
                We pride ourselves on our superior approach to software development <span className="yellow">and our unwavering commitment to</span>
                serving corporate and large-scale clients <span className="yellow"> with top-notch technical consultancy</span>
                <br />
                <br />
                Our solution-focused strategy ensures that we deliver
                    <b className="yellow">  the expertise </b>
                    and support they need to thrive in the digital landscape.
                  
                </p>
              </Col>
              <Col md={4} className="myAvtar">
                <Tilt>
                  <img src={LaptopImg} className="img-fluid" alt="avatar" />
                </Tilt>
              </Col>
              </Row>
              </Container>
    </>
      )
}

export default About