import React from "react";
import { Container,Row,Col} from "react-bootstrap";

import Particle from '../components/Particle'
import Techstack from "../components/Skillset/Techstack";
import Toolstack from "../components/Skillset/Toolstack";
import Leetcode from "../components/Skillset/Leetcode";
import Github from "../components/Skillset/Github";
import {
  SiAdobeaftereffects,
  SiAdobeillustrator,
  SiAdobephotoshop,
  SiAdobelightroom,
  SiFlutter,
  SiAndroid,
} from "react-icons/si";
import {
  DiReact
} from "react-icons/di";
import {
  AiFillCamera
} from "react-icons/ai";
const Services = () => {
  return (
    <Container fluid className="about-section">
      <Particle />
      <h1 className="project-heading" style={{marginTop:"10%"}}>
      Graphic  <strong className="yellow">Services </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        < SiAdobeaftereffects/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        < SiAdobeillustrator/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        < SiAdobephotoshop/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        < SiAdobelightroom/>
      </Col>
      </Row>
      <Container>
        <h1 className="project-heading">
        Fontend <strong className="yellow">Services </strong>
        </h1>
        <Techstack />

        <h1 className="project-heading">
          <strong className="yellow">Backend </strong> Services
        </h1>
        <Toolstack />
        <h1 className="project-heading">
        <strong className="yellow">Mobile </strong>  App  Services
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        < DiReact/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        < SiFlutter/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroid/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <AiFillCamera/>
      </Col>
      </Row>
        <Leetcode />
        <Github />
      </Container>
    </Container>
  )
}

export default Services