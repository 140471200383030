import React from 'react';
import img1 from '../project/img1.png'
import img2 from '../project/img2.png'
import img3 from '../project/img3.png'
import img4 from '../project/img4.png'
import img5 from '../project/img5.png'

const Projects = () => {
  return (
    <>
      <h1 style={{ fontSize: "2.6em", marginTop: "10%", color: "white" }}>
        Recent Top<span className="yellow"> Works </span>
      </h1>
      <p style={{ color: "white" }}>
        Here are a few projects I've worked on recently
      </p>

      <div className="image-text-container">
        <div className="image-container">
          <div>
            <img
              src={img1}
              alt="Coindom Crypto Coins"
              style={{ width: "100%", maxWidth: "400px", marginLeft: "6%" }}
              className="image-hover"
            />
          </div>
        </div>
        <div className="text-container">
          <h1 style={{ color: "white", marginLeft: "9%", marginTop: "2em" }}>
            Coindom Crypto Coins
          </h1>
          <p style={{ color: "white", marginLeft: "9%", fontSize: "1.2em" }}>
            Coindom is a cryptocurrency app that provides users with real-time
            information on various cryptocurrencies. With Coindom, you can view
            the details of any crypto, including its chart for different periods.
          </p>
          <a href="https://coindom-gold.vercel.app/">
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "8%",
                color: "white",
              }}
            >
              Demo
            </button>
          </a>
        </div>
      </div>
      <div className="image-text-container">
        <div className="image-container">
          <div>
            <img
              src={img2}
              alt="lumapix"
              style={{ width: "100%", maxWidth: "400px", marginLeft: "6%" }}
              className="image-hover"
            />
          </div>
        </div>
        <div className="text-container">
          <h1 style={{ color: "white", marginLeft: "9%", marginTop: "2em" }}>
             lumapix 
          </h1>
          <p style={{ color: "white", marginLeft: "9%", fontSize: "1.2em" }}>
          Meet lumapix – the responsive image download app that lets you search and download images with ease..
          </p>
          <a href="https://lumapix.vercel.app/">
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "8%",
                color: "white",
              }}
            >
              Demo
            </button>
          </a>
        </div>
      </div>
      <div className="image-text-container">
        <div className="image-container">
          <div>
            <img
              src={img3}
              alt="OUT-Fit"
              style={{ width: "100%", maxWidth: "400px", marginLeft: "6%" }}
              className="image-hover"
            />
          </div>
        </div>
        <div className="text-container">
          <h1 style={{ color: "white", marginLeft: "9%", marginTop: "2em" }}>
            OUT-Fit
          </h1>
          <p style={{ color: "white", marginLeft: "9%", fontSize: "1.2em" }}>
          The Adaptive Online Clothing Store Looking for an online clothing store that anticipates your needs? Look no further than Outfit Our platform is designed with both men and women in mind, offering a vast selection of clothes and accessories. With its fully responsive system, Outfit is a breeze to navigate and shop.
          </p>
          <a href="https://react-ecomm-ten.vercel.app/">
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "8%",
                color: "white",
              }}
            >
              Demo
            </button>
          </a>
        </div>
      </div>
      <div className="image-text-container">
        <div className="image-container">
          <div>
            <img
              src={img4}
              alt="Mermoy -Game"
              style={{ width: "100%", maxWidth: "400px", marginLeft: "6%" }}
              className="image-hover"
            />
          </div>
        </div>
        <div className="text-container">
          <h1 style={{ color: "white", marginLeft: "9%", marginTop: "2em" }}>
            Mermoy -Game
          </h1>
          <p style={{ color: "white", marginLeft: "9%", fontSize: "1.2em" }}>
          "Meet memory-game -Responisve easy Game free online websites platfrom"
          </p>
          <a href="https://memory-game-5h3f1h6hv-owaisdurvesh.vercel.app/">
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "8%",
                color: "white",
              }}
            >
              Demo
            </button>
          </a>
        </div>
      </div>
      <div className="image-text-container">
        <div className="image-container">
          <div>
            <img
              src={img5}
              alt="GYM Websites"
              style={{ width: "100%", maxWidth: "400px", marginLeft: "6%" }}
              className="image-hover"
            />
          </div>
        </div>
        <div className="text-container">
          <h1 style={{ color: "white", marginLeft: "9%", marginTop: "2em" }}>
              GYM Websites
          </h1>
          <p style={{ color: "white", marginLeft: "9%", fontSize: "1.2em" }}>
          Gym websites typically provide details about membership options, including pricing for different plans, contract terms, and any special promotions or discounts. They might also outline what is included in each membership level.
          </p>
          <a href="https://coindom-gold.vercel.app/">
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "8%",
                color: "white",
              }}
            >
              Demo
            </button>
          </a>
        </div>
      </div>

      <style>
        {`
          @media (max-width: 768px) {
            h1 {
              font-size: 2em;
            }
            p {
              font-size: 1em;
            }
          }
        `}
      </style>
    </>
  );
};

export default Projects;
