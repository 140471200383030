import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import {
  DiJavascript1,
  DiReact,
} from "react-icons/di";
import {
  SiHtml5,
  SiPhp
} from "react-icons/si";
import { BsFacebook} from 'react-icons/bs'
const About = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Technology<span className="yellow"> of Your Desire ! </span>
            </h1>
            <p className="home-about-body">
              Grapecodev provides local and international clients with{" "}
              <span className="yellow">100% </span>
              successful project delivery <span className="yellow"> in the software consulting industry.</span>
              <br />
              <br />
              We seek out the most creative individuals who, using our design-focused methodology
              <br />
              <br />
              and project execution strategies,
              <b className="yellow"> can provide our clients </b>with successful projects.  
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={LaptopImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>OUR Services</h1>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
          <Col xs={4} md={2} className="tech-icons">
            < DiJavascript1 />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            < DiReact />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            < SiPhp />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            < SiHtml5 />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Please don't hesitate to reach out to me and <span className="yellow">connect.</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/muhammad-uzair-3114b3215/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="linkedin"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/uzair.yaseen_"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <BsFacebook />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
