import React from 'react'
import { Row, Col } from "react-bootstrap";
import {
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import './Social.css'
import { BsFacebook} from 'react-icons/bs'
const Social = () => {
  return (
    <Row>
    <Col md={12} className="home-about-social">
      <h1>FIND ME ON</h1>
      <p>
        Please don't hesitate to reach out to me and <span className="yellow">connect.</span>
      </p>
      <ul className="home-about-social-links">
        <li className="social-icons">
        </li>
        <li className="social-icons">
          <a
            href="https://www.linkedin.com/in/muhammad-uzair-3114b3215/"
            target="_blank"
            rel="noreferrer"
            className="icon-colour  home-social-icons"
            aria-label="linkedin"
          >
            <FaLinkedinIn />
          </a>
        </li>
        <li className="social-icons">
          <a
            href="https://www.instagram.com/uzair.yaseen_"
            target="_blank"
            rel="noreferrer"
            className="icon-colour home-social-icons"
            aria-label="instagram"
          >
            <AiFillInstagram />
          </a>
        </li>
        <li className="social-icons">
          <a
            href="#"
            target="_blank"
            rel="noreferrer"
            className="icon-colour home-social-icons"
            aria-label="instagram"
          >
            <BsFacebook />
          </a>
        </li>
      </ul>
    </Col>
  </Row>

  )
}

export default Social